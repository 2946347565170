import React from 'react';
import Media from 'react-media';
import { useSalesPhoneNumber } from '../../../../common/hooks/useSalesPhoneNumber';
import { Nav } from '../../../common/Elements';
import { StyleSystemProps } from '../../../common/Elements/Elements';
import { LocaleLink } from '../../../common/links/LocaleLink';
import Text from '../../../common/Text/Text';
import { trackNavigationData } from '../utils/navigation.tracking';
import { useHeaderContext } from '../header.context';
import { TopNavOptions } from '../../../../contentful/content-types/page/page.interface';
import { CustomCTAButton } from './header.interface';

interface NavTemplateProps {
  topNavOption?: TopNavOptions;
  customCTAButton?: CustomCTAButton;
}
const navOptions = new Map([
  ['minimized', 'minimized'],
  ['only-phone', 'only-phone'],
  ['no-phone', 'no-phone'],
  ['empty', 'empty'],
]);

export const NavTemplatesMenu: React.FC<NavTemplateProps> = ({ topNavOption, customCTAButton }) => {
  const salesPhoneNumber = useSalesPhoneNumber();
  const context = useHeaderContext();

  const CTA =
    customCTAButton?.url && customCTAButton.text
      ? { ...customCTAButton, type: 'custom' }
      : {
          url: '/under-attack-hotline',
          text: context?.localizedStrings?.['underAttack'],
          type: 'underAttack',
        };

  return (
    <Media
      queries={{
        large: '(min-width: 1120px)',
      }}
      defaultMatches={{ large: true }}
    >
      {matches => {
        const ctaProps: Pick<
          StyleSystemProps,
          'paddingHorizontal' | 'paddingVertical' | 'backgroundColor' | 'border'
        > = {
          paddingHorizontal: matches.large ? 4 : 2,
          paddingVertical: 1,
          backgroundColor: 'orange1',
          border: 'none',
        };
        return (
          <Nav
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            width="100%"
            justifyContent={['start', 'end']}
            marginTop={[topNavOption === 'empty' ? 0 : 4, 0]}
            flexDirection={['column', 'row']}
          >
            {topNavOption === navOptions.get('minimized') ||
            topNavOption === navOptions.get('only-phone') ? (
              <LocaleLink
                {...ctaProps}
                paddingHorizontal={[0, matches.large ? 4 : 2]}
                to={`tel:${salesPhoneNumber.number}`}
                fontWeight="bold"
                className="black body-2 newNav-link"
                tabIndex={0}
                marginRight={4}
                variant="anchor"
                display="block"
                alignSelf={['start', 'center']}
                paddingBottom={[2, 1]}
                backgroundColor="transparent"
                onClick={() =>
                  trackNavigationData({
                    element: 'link',
                    label: `utilitynav|${salesPhoneNumber.displayedNumber}:[${salesPhoneNumber.number}]`,
                  })
                }
              >
                <Text tag="Span" variant="body2-bold" color="black">
                  {context?.localizedStrings?.['phoneNumberWithColon']}
                  {salesPhoneNumber.displayedNumber}
                </Text>
              </LocaleLink>
            ) : null}
            {topNavOption === navOptions.get('minimized') ||
            topNavOption === navOptions.get('no-phone') ? (
              <LocaleLink
                tabIndex={0}
                marginRight={[0, 2]}
                variant="anchor"
                display="block"
                width={['100%', 'auto']}
                textAlign={'center'}
                className="newNav-link"
                to={CTA.url || '/under-attack-hotline'}
                {...ctaProps}
                onClick={() =>
                  trackNavigationData({
                    element: 'button',
                    label: `utilitynav|${CTA.text}:[${CTA.url}]`,
                  })
                }
              >
                <Text tag="Span" variant="body2-bold" color="white">
                  {CTA.type === 'custom' ? CTA.text : context?.localizedStrings?.['underAttack']}
                </Text>
              </LocaleLink>
            ) : null}
          </Nav>
        );
      }}
    </Media>
  );
};
