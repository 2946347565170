import React from 'react';
import { MaybeRedwood, Redwood } from '../../config';

export const useRedwood = () => {
  const [redwood, setRedwood] = React.useState<Redwood | undefined>(undefined);
  React.useEffect(() => {
    const redwood = (window as MaybeRedwood).redwood;
    setRedwood(redwood);
  }, []);
  return redwood;
};
