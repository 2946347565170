import React from 'react';
import Media from 'react-media';
import { Div, Row, Col } from '../../../../common/Elements';
import { useTabContext } from '../../tabs.context';
import { MenuItem } from './MenuItem';
import { MenuItemGroup } from './MenuItemGroup';

interface Props {
  hasModalMerchandize?: boolean;
}

export const MenuMain: React.FC<Props> = (props: Props) => {
  const { tab } = useTabContext();

  const hasModalMerchandize = tab.tabProps.tabData?.navigationLinks?.filter(
    link => link.specialLinkType === 'MERCHANDIZE'
  )?.length;

  const tabLinks = tab.tabProps.tabData?.navigationLinks?.filter(link => !link.specialLinkType);
  const tabGroups = tab.tabProps.tabData?.navigationItems;

  return (
    <Media
      queries={{
        small: '(max-width: 991px)',
        large: '(min-width: 992px)',
      }}
      defaultMatches={{ large: true }}
    >
      {matches => (
        <Div
          style={{
            columnCount: matches.small ? 1 : hasModalMerchandize ? 2 : 3,
            columnGap: 64,
          }}
        >
          {tabLinks?.map(link => (
            <MenuItem
              hasModalMerchandize={!!hasModalMerchandize}
              link={link}
              key={link.contentfulId}
            />
          ))}
          {tabGroups?.map(group => (
            <MenuItemGroup
              hasModalMerchandize={!!hasModalMerchandize}
              group={group}
              key={group.contentfulId}
            />
          ))}
        </Div>
      )}
    </Media>
  );
};
