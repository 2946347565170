import React from 'react';
import { ContentfulNavigationLink } from '../../../../../contentful/content-types/navigationlink';
import { Div, Span } from '../../../../common/Elements';
import { LocaleLink } from '../../../../common/links/LocaleLink';
import Text from '../../../../common/Text/Text';
import { useHeaderContext } from '../../header.context';
import { trackNavigationData } from '../../utils/navigation.tracking';

interface Props {
  link: ContentfulNavigationLink;
  grouped?: boolean;
  hasModalMerchandize?: boolean;
}

export const MenuItem: React.FC<Props> = ({ link, grouped, hasModalMerchandize }) => {
  const [hovered, setHovered] = React.useState<boolean>(false);
  const { modal } = useHeaderContext();

  return (
    <Div
      style={{
        margin: 0,
        display: 'grid',
        gridTemplateRows: '1fr auto',
        breakInside: 'avoid',
      }}
    >
      <LocaleLink
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        openInNewTab={!!link.openInNewWindow}
        variant={link.isExternalUrl ? 'anchor' : 'link'}
        display="block"
        className="newNav-link"
        to={link.url || ''}
        onClick={() =>
          trackNavigationData({
            element: 'link',
            label: `mainnav|${modal?.modalProps?.modalData?.name}_${link.title}:[${link.url}]`,
          })
        }
      >
        <Text
          tag="Span"
          display="block"
          marginBottom={grouped && !link.description ? [2, 2, 2, 1] : [2, 2, 2, 0]}
          color={hovered ? 'bluehover' : 'black'}
          variant="body2-bold"
        >
          {link.title}{' '}
          {link.badges?.map(badge => (
            <Span
              paddingVertical="4px"
              paddingHorizontal={1}
              marginLeft={2}
              key={badge}
              fontWeight={7}
              fontSize={1}
              display="inline-flex"
              backgroundColor="blue1"
              color="white"
            >
              {badge}
            </Span>
          ))}
        </Text>
        {link.description && (
          <Text
            tag="Span"
            display={['none', 'block', 'none', 'block']}
            color="gray0"
            marginBottom={grouped ? 3 : 4}
            variant="body3"
          >
            {link.description}
          </Text>
        )}
      </LocaleLink>
    </Div>
  );
};
