import React from 'react';
import { Div } from '../../../../common/Elements';
import Text from '../../../../common/Text/Text';
import { useHeaderContext } from '../../header.context';
import { useTabContext } from '../../tabs.context';
import { Merchandize } from './Merchandize';

interface Props {}

export const Merchandizing: React.FC<Props> = ({}) => {
  const { tab } = useTabContext();
  const { localizedStrings } = useHeaderContext();

  const merchandizingLinks = tab.tabProps.tabData?.navigationLinks?.filter(
    link => link.specialLinkType === 'MERCHANDIZE'
  );

  return (
    <Div width="100%" style={{ maxWidth: '233px' }}>
      <Div marginBottom={2}>
        <Text variant="headline4">{localizedStrings?.Featured}</Text>
      </Div>
      {merchandizingLinks?.map((merchandize, index) => (
        <Merchandize merchandize={merchandize} key={`${merchandize.contentfulId}${index}`} />
      ))}
    </Div>
  );
};
