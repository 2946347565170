import React from 'react';
import { initialModalState, ModalAction, ModalState } from './reducers/modal.reducer';
export interface ModalContext {
  modal: ModalState;
  dispatch: React.Dispatch<ModalAction>;
  localizedStrings?: {
    [key: string]: any;
  };
}

export const HeaderContext = React.createContext<ModalContext>({
  modal: initialModalState,
  dispatch: () => {},
});
export const useHeaderContext = (): ModalContext => React.useContext(HeaderContext);
