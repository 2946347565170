import React from 'react';
import Modal from 'react-modal';
import { Div, Header } from '../../../common/Elements';
import { useHeaderContext } from '../header.context';
import { MainHeader } from '../MainHeader';
import { MegaMenu } from './ModalComponents/MegaMenu';
import { ContentfulNavNavigationGroup } from '../../../../contentful/content-types/nav-navigation-group';
import { ContentfulNavigation } from '../../../../contentful/content-types/navigation';
import { MODAL_TYPES } from '../reducers/modal.reducer';

interface Props {
  headerData: ContentfulNavNavigationGroup;
}

export const MenuModal: React.FC<Props> = ({ headerData }) => {
  const { modal, dispatch } = useHeaderContext();

  const hasSelectedMenu = modal.modalProps.selectedMenu;

  const mobileVerticalPadding = hasSelectedMenu ? 3 : 0;

  const closeModal = () => {
    dispatch({
      type: MODAL_TYPES.HIDE,
    });
  };

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={modal.open}
      className="bg-blue5 flex flex-column h-auto min-h-100 outline-0"
      overlayClassName="fixed top-0 bottom-0 left-0 right-0 bg-blue5 z-max overflow-y-scroll br-refresh"
      contentLabel="nav-menu"
      shouldCloseOnEsc={true}
      onRequestClose={closeModal}
      ariaHideApp={true}
      bodyOpenClassName="overflow-hidden"
    >
      <Header display="flex" flexDirection="column" flex="auto" id="modal-nav">
        <MainHeader headerData={headerData} modalNav />
        <Div
          width="100%"
          display="flex"
          flex="auto"
          flexDirection="column"
          paddingHorizontal={[3, 3, 3, 6]}
          style={{
            maxWidth: '1440px',
            margin: '0 auto',
            height: 'auto',
            minHeight: hasSelectedMenu ? '100%' : '',
            overflow: hasSelectedMenu ? 'overflow-hidden-l' : '',
          }}
          paddingVertical={[mobileVerticalPadding, mobileVerticalPadding, mobileVerticalPadding, 5]}
        >
          <MegaMenu rightNavData={headerData?.rightNav} />
        </Div>
      </Header>
    </Modal>
  );
};
