import React from 'react';
import { Div } from '../../common/Elements';
import { TopNav } from './components/Topnav';
import { MainNav } from './components/Mainnav';
import { ContentfulNavNavigationGroup } from '../../../contentful/content-types/nav-navigation-group';
import { TopNavOptions } from '../../../contentful/content-types/page/page.interface';
import { CustomCTAButton, isCustomNavOption } from './components/header.interface';

interface HeaderProps {
  modalNav?: boolean;
  headerData: ContentfulNavNavigationGroup;
  customHeaderLogoUrl?: string | undefined;
  topNavOption?: TopNavOptions;
  customCTAButton?: CustomCTAButton;
}

export const MainHeader: React.FC<HeaderProps> = ({
  modalNav,
  headerData,
  customHeaderLogoUrl,
  topNavOption,
  customCTAButton,
}) => {
  return (
    <Div
      paddingBottom={[0, 0, 0, 1]}
      paddingTop={[0, 0, 0, '4px']}
      position="sticky"
      zIndex="max"
      color="white"
      className="top-0"
      backgroundColor={modalNav ? 'blue5' : 'white'}
    >
      <Div
        width="100%"
        marginVertical={0}
        paddingHorizontal={[3, 3, 3, 6]}
        style={{
          maxWidth: '1440px',
          margin: '0 auto',
        }}
      >
        {!isCustomNavOption(topNavOption) ? (
          <Div
            display={['none', 'none', 'none', 'block']}
            paddingTop={1}
            lineHeight={0}
            paddingBottom={1}
          >
            <TopNav utilityNav={headerData?.utilityNav} />
          </Div>
        ) : null}
        <Div paddingVertical={[2, 2, 2, 1]}>
          <MainNav
            headerData={headerData}
            modalNav={modalNav}
            customHeaderLogoUrl={customHeaderLogoUrl}
            topNavOption={topNavOption}
            customCTAButton={customCTAButton}
          />
        </Div>
      </Div>
    </Div>
  );
};
