import { TopNavOptions } from '../../../../contentful/content-types/page/page.interface';

export const isCustomNavOption = (navOption?: TopNavOptions) => {
  return (
    navOption === 'only-phone' ||
    navOption === 'minimized' ||
    navOption === 'no-phone' ||
    navOption === 'empty'
  );
};

export type CustomCTAButton = {
  text?: string;
  url?: string;
};
