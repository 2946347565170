import React from 'react';
import Media from 'react-media';
import { ButtonCTA } from 'src/components/common/ButtonLink';
import { ContentfulNavigation } from '../../../../contentful/content-types/navigation';
import { Nav } from '../../../common/Elements';
import { StyleSystemProps } from '../../../common/Elements/Elements';
import { LocaleLink } from '../../../common/links/LocaleLink';
import Text from '../../../common/Text/Text';
import { trackNavigationData } from '../utils/navigation.tracking';
import { CustomCTAButton } from './header.interface';

interface Props {
  rightNavData?: ContentfulNavigation;
  customCTAButton?: CustomCTAButton;
}

export const RightNav: React.FC<Props> = ({ rightNavData, customCTAButton }) => {
  const hasCustomCTA = customCTAButton?.text && customCTAButton?.url;

  const navItems = hasCustomCTA
    ? rightNavData?.navigationItems?.filter(item => item.mainLink.specialLinkType !== 'CTA')
    : rightNavData?.navigationItems;

  return (
    <Media
      queries={{
        large: '(min-width: 1120px)',
      }}
      defaultMatches={{ large: true }}
    >
      {matches => {
        const ctaProps: Pick<
          StyleSystemProps,
          'paddingHorizontal' | 'paddingVertical' | 'backgroundColor' | 'border'
        > = {
          paddingHorizontal: matches.large ? 4 : 2,
          paddingVertical: 1,
          backgroundColor: 'orange0',
          border: 'none',
        };
        return (
          <Nav display="flex" flexWrap="wrap" alignItems="center" width="100%" justifyContent="end">
            {navItems?.map((item, index) => (
              <LocaleLink
                tabIndex={0}
                marginRight={
                  rightNavData?.navigationItems &&
                  rightNavData?.navigationItems?.length - 1 === index
                    ? 0
                    : matches.large
                    ? 4
                    : 2
                }
                variant="anchor"
                display="block"
                className="newNav-link"
                key={item.contentfulId}
                to={item.mainLink.url}
                {...(item.mainLink.specialLinkType === 'CTA' ? ctaProps : {})}
                onClick={() =>
                  trackNavigationData({
                    element: item.mainLink.specialLinkType === 'CTA' ? 'button' : 'link',
                    label: `utilitynav|${item.mainLink.title}:[${item.mainLink.url}]`,
                  })
                }
              >
                <Text
                  tag="Span"
                  variant="body2-bold"
                  color={item.mainLink.specialLinkType === 'CTA' ? 'white' : 'blue1'}
                >
                  {item.mainLink.title}
                </Text>
              </LocaleLink>
            ))}
            {hasCustomCTA && (
              <LocaleLink
                tabIndex={0}
                variant="anchor"
                display="block"
                className="newNav-link"
                to={customCTAButton?.url || ''}
                {...ctaProps}
                onClick={() =>
                  trackNavigationData({
                    element: 'button',
                    label: `utilitynav|${customCTAButton?.text}:[${customCTAButton?.url}]`,
                  })
                }
              >
                <Text tag="Span" variant="body2-bold" color={'white'}>
                  {customCTAButton?.text}
                </Text>
              </LocaleLink>
            )}
          </Nav>
        );
      }}
    </Media>
  );
};
