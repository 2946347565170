import React, { Reducer } from 'react';
import { MenuModal } from './components/MenuModal';
import {
  initialModalState,
  menuModalReducer,
  ModalAction,
  ModalState,
} from './reducers/modal.reducer';
import { HeaderContext } from './header.context';
import { MainHeader } from './MainHeader';
import { Header as HTMLHeader } from '../../common/Elements';
import { ContentfulNavNavigationGroup } from '../../../contentful/content-types/nav-navigation-group';
import { TopNavOptions } from '../../../contentful/content-types/page/page.interface';
import { CustomCTAButton } from './components/header.interface';

interface HeaderProps {
  headerData: ContentfulNavNavigationGroup;
  customHeaderLogoUrl?: string | undefined;
  topNavOption?: TopNavOptions;
  customNav?: ContentfulNavNavigationGroup;
  customCTAButton?: CustomCTAButton;
  localizedStrings?: {
    [key: string]: any;
  };
}

const Header: React.FC<HeaderProps> = ({
  headerData,
  customHeaderLogoUrl,
  topNavOption,
  customNav,
  customCTAButton,
  localizedStrings,
}) => {
  const [modal, dispatchModal] = React.useReducer<Reducer<ModalState, ModalAction>>(
    menuModalReducer,
    initialModalState
  );

  return (
    <HeaderContext.Provider value={{ modal, dispatch: dispatchModal, localizedStrings }}>
      <HTMLHeader id="modal-topnav" zIndex={5} position="sticky" className="top-0">
        {topNavOption === 'custom' && customNav ? (
          <>
            <MainHeader
              headerData={customNav}
              customHeaderLogoUrl={customHeaderLogoUrl}
              topNavOption={topNavOption}
              customCTAButton={customCTAButton}
            />
            <MenuModal headerData={customNav} />
          </>
        ) : (
          <>
            <MenuModal headerData={headerData} />
            <MainHeader
              headerData={headerData}
              topNavOption={topNavOption}
              customCTAButton={customCTAButton}
              customHeaderLogoUrl={customHeaderLogoUrl}
            />
          </>
        )}
      </HTMLHeader>
    </HeaderContext.Provider>
  );
};

export default Header;
