import React from 'react';
import { ButtonCTA } from '../../../../common/buttons/Button';
import { Button, Col, Div, Row, Span } from '../../../../common/Elements';
import { LocaleLink } from '../../../../common/links/LocaleLink';
import Text from '../../../../common/Text/Text';
import { useHeaderContext } from '../../header.context';
import { MenuTabList } from './MenuTabList';
import { Icon } from '../../../../common/Icon';
import { CFArrowBackward } from '../../../../common/CFIcons/CFArrowBackward';
import { MODAL_TYPES } from '../../reducers/modal.reducer';
import { ContentfulNavigation } from '../../../../../contentful/content-types/navigation';
import { trackNavigationData } from '../../utils/navigation.tracking';

interface Props {
  rightNavData: ContentfulNavigation;
}

export const MenuSide: React.FC<Props> = ({ rightNavData }) => {
  const { modal, localizedStrings, dispatch } = useHeaderContext();
  const modalData = modal.modalProps.modalData;

  if (!modalData) return null;

  const bottomLinks = modalData?.navigationLinks?.filter(
    link => link.specialLinkType === 'NAV_BOTTOM_LINK'
  );

  const signUpNavItem = rightNavData?.navigationItems?.find(
    nav => nav.contentfulId === '7rbuvTQnwySxPNN0BVU8fv'
  );

  const cta = modalData?.navigationLinks?.find(link => link.specialLinkType === 'CTA');

  return (
    <Row heightPercentage={100}>
      <Col heightPercentage={100} display="flex" flexDirection="column" lg={12}>
        <Div heightPercentage={100} display="flex" flexDirection="column" id="modal-sidenav">
          <Div display="flex" justifyContent="between" marginBottom={[1, 1, 1, 3]}>
            <Div id="modal-title">
              <Text variant="headline3" color="black">
                {modalData?.name}
              </Text>
            </Div>
            <Button
              type="button"
              padding={0}
              backgroundColor="transparent"
              className="newNav-button"
              id="close-modal-nav"
              onClick={() => {
                dispatch({ type: MODAL_TYPES.CLEAR_SELECTED });
                trackNavigationData({
                  element: 'button',
                  label: `mainnav|${modalData?.name}|mainnav-sidebar_back-button`,
                });
              }}
              display={['flex', 'none', 'flex', 'none']}
              alignItems="center"
            >
              <CFArrowBackward size={16} color="black" />
              <Text wordBreak="normal" marginLeft={1} tag="Span" variant="body2-bold">
                {localizedStrings?.Back}
              </Text>
            </Button>
          </Div>
          <Div flex="auto">
            <MenuTabList />
          </Div>
          <Div marginTop={7} display={['block', 'none', 'block', 'none']}>
            <ButtonCTA
              href={signUpNavItem?.mainLink.url || ''}
              marginBottom={2}
              buttonStyle="auto"
              className="newNav-link"
              buttonColor="orange"
              onClick={() =>
                trackNavigationData({
                  element: 'button',
                  label: `mainnav|${modalData?.name}|mainnav-sidebar_${
                    signUpNavItem?.name
                  }:[${signUpNavItem?.mainLink.url || ''}]`,
                })
              }
            >
              {signUpNavItem?.name}
            </ButtonCTA>
            <ButtonCTA
              href="/plans/enterprise/contact"
              buttonStyle="auto"
              marginBottom={2}
              className="newNav-link"
              buttonColor="orange-outline"
              onClick={() =>
                trackNavigationData({
                  element: 'button',
                  label: `mainnav|${modalData?.name}|mainnav-sidebar_Contact Sales:[/plans/enterprise/contact]`,
                })
              }
            >
              {localizedStrings?.Contact_Sales}
            </ButtonCTA>
          </Div>
          <Div display={['none', 'none', 'none', 'flex']} marginBottom={3}>
            {bottomLinks?.map(link => (
              <Div
                display="flex"
                justifyContent="between"
                alignItems="center"
                border="bottom"
                borderColor="gray2"
                width="100%"
                key={link.contentfulId}
                paddingVertical={2}
              >
                <LocaleLink
                  display="block"
                  openInNewTab={!!link.openInNewWindow}
                  variant="anchor"
                  className="newNav-link"
                  to={link.url || ''}
                  onClick={() =>
                    trackNavigationData({
                      element: 'link',
                      label: `mainnav|${modalData?.name}|mainnav-sidebar_${link.title}:[${link.url}]`,
                    })
                  }
                >
                  <Text
                    variant="body2-bold"
                    tag="Span"
                    display="flex"
                    alignItems="center"
                    color="blue1"
                  >
                    {link.title}
                    <Span lineHeight={0} marginLeft="4px">
                      <Icon type="link-caret" />
                    </Span>
                  </Text>
                </LocaleLink>
              </Div>
            ))}
          </Div>
          <Div display={['none', 'none', 'none', 'flex']}>
            <ButtonCTA
              buttonStyle="auto"
              className="newNav-link"
              width="100%"
              href={cta?.url}
              buttonColor="orange"
              onClick={() =>
                trackNavigationData({
                  element: 'button',
                  label: `mainnav|${modalData?.name}|mainnav-sidebar_${cta?.title}:[${cta?.url}]`,
                })
              }
            >
              {cta?.title}
            </ButtonCTA>
          </Div>
        </Div>
      </Col>
    </Row>
  );
};
